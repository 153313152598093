
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
  modalController,
  toastController,
} from "@ionic/vue";
import { starOutline, star } from "ionicons/icons";
import axios from "axios";

export default defineComponent({
  name: "Review",

  components: {
    IonPage,
    IonContent,
    IonText,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonTextarea,
  },
  methods: {
    onFotoChange($event) {
      const file = $event.target.files[0];
      this.urlImg = URL.createObjectURL(file);
      this.fileImg = file;
    },
    onVideoChange($event) {
      const file = $event.target.files[0];
      this.urlVideo = file.name;
      this.fileVideo = file;
    },
    async closeModal() {
      await modalController.dismiss();
    },
    async kirimReview() {
      const formdata = new FormData();
      formdata.append("id_trx", this.menu.id_trx);
      formdata.append("id_menu", this.menu.id_menu);
      formdata.append("rate", this.rate);
      this.fileImg && formdata.append("image", this.fileImg);
      this.fileVideo && formdata.append("video", this.fileVideo);
      this.comment && formdata.append("comment", this.comment);
      formdata.append("id_driver", this.menu.info_driver.id_driver);
      formdata.append("rate_driver", this.driverRate);
      this.driverComment &&
        formdata.append("driver_comment", this.driverComment);

      this.loadingRate = true;
      await axios
        .post("rating", formdata)
        .then(async ({ data }) => {
          const toast = await toastController.create({
            message: data.message,
            duration: 1500,
          });
          toast.present();
          await modalController.dismiss();
          this.loadingRate = false;
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 1500,
          });
          toast.present();
          await modalController.dismiss();
          this.loadingRate = false;
        });
    },
  },
  setup() {
    return { starOutline, star };
  },
  data() {
    return {
      loadingRate: false,
      driver: {} as any,
      driverRate: 0,
      rate: 0,
      comment: null,
      driverComment: null,
      urlImg: null,
      urlVideo: null,
      fileImg: null,
      fileVideo: null,
    };
  },
  props: {
    menu: {} as any,
  },
});
